body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
}


@font-face {
  font-family: 'MadeMirage';
  src: url('assets/fonts/made_mirage_black-webfont.woff2') format('woff2'),
       url('assets/fonts/made_mirage_black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;

}

@font-face {
  font-family: 'MadeMirage';
  src: url('assets/fonts/made_mirage_bold-webfont.woff2') format('woff2'),
       url('assets/fonts/made_mirage_bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}




@font-face {
  font-family: 'MadeMirage';
  src: url('assets/fonts/made_mirage_medium-webfont.woff2') format('woff2'),
       url('assets/fonts/made_mirage_medium-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'made_mirageregular';
  src: url('assets/fonts/made_mirage_regular-webfont.woff2') format('woff2'),
       url('assets/fonts/made_mirage_regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MadeMirage';
  src: url('assets/fonts/made_mirage_thin-webfont.woff2') format('woff2'),
       url('assets/fonts/made_mirage_thin-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
/* 
@font-face {
  font-family: 'TT Norms';
  src: url('assets/fonts/TTNorms-Regular.woff2') format('woff2'),
      url('assets/fonts/TTNorms-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms';
  src: url('assets/fonts/TTNorms-Light.woff2') format('woff2'),
      url('assets/fonts/TTNorms-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms';
  src: url('assets/fonts/TTNorms-Italic.woff2') format('woff2'),
      url('assets/fonts/TTNorms-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms';
  src: url('assets/fonts/TTNorms-Bold.woff2') format('woff2'),
      url('assets/fonts/TTNorms-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms';
  src: url('assets/fonts/TTNorms-BoldItalic.woff2') format('woff2'),
      url('assets/fonts/TTNorms-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms';
  src: url('assets/fonts/TTNorms-Black.woff2') format('woff2'),
      url('assets/fonts/TTNorms-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms';
  src: url('assets/fonts/TTNorms-BlackItalic.woff2') format('woff2'),
      url('assets/fonts/TTNorms-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
} */


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
*{
  font-family: "Roboto";
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}