input[type],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 16px 0px;
    display: inline-block;
    /* border: 1px solid #ccc; */
    /* border-radius: 4px; */
    background-color: transparent;
    border: none;
    outline: none;
    color: #333333;
    box-sizing: border-box;
    font-size: 1em;
    font-weight: 400;
}
input:focus {
    outline: none;
}

.option-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    width: 100%;
}
.hide{
    display: none !important;
}
.option-btn-selected{
    cursor: pointer;
    width: 50%;
    padding: 1em;
    box-sizing: border-box;
    background: #333333;
    color: white;
    border-radius: 1em;
    
    font-size: 1.25em;
    font-weight: 700;
}

.option-btn-not-selected{
    cursor: pointer;
    width: 50%;
    padding: 1em;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #333333;
    color: #333333;
    border-radius: 1em;
    
}

.dashboard-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 850px;
    padding: 5%;
    box-sizing: border-box;
}

.dis-content{
    display: contents;
}

.small-text{
    font-size: 0.75em;
}

.no-item{
    
    font-size: 1.25em;
}

.title-text{
    font-weight: 700;
    font-size: 1.5em;
    margin-bottom: 1em;
}

.basic-info-grp{
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #33333340;
    color: #333333;
    border-radius: 0.5em;
    margin-top: 1em;
    text-align: start;
    gap: 0.75em;
    display: flex;
    flex-direction: column;
}

.result{
    font-weight: 700;
    font-size: 1em;
    margin-left: 0.5em;
}

.add-item-btn{
    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 0.5em;
    box-sizing: border-box;
    background: #00000010;
    border: 1px solid #333333;
    color: #333333;
    border-radius: 0.5em;
    font-size: 1.25em;
    font-weight: 700;
    
}

.loading-bg{
    background: #000000D0;
    backdrop-filter: blur(3px);
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 200;
    gap: 1em;
    padding: 1em;
    box-sizing: border-box;
}

.send-btn{
    margin-top: 2em;
    width: 100%;
    cursor: pointer;
    pointer-events: visible;
    color: white;
    background-color: black;
    padding: 0.35em 0.5em 0.35em 0.5em;
    border-radius: 0.5em;
    
    font-size: 1.25em;
    z-index: 10;
    font-weight: 700;
    text-align: center;
}

.alert-text{
    color: white !important;
    font-weight: 700;
    
}

.white-border{
    border: 2px solid white;
}

.send-now-btn{
    margin-top: 2em;
    width: 50%;
    cursor: pointer;
    pointer-events: visible;
    color: black;
    background-color: white;
    padding: 0.35em 0.5em 0.35em 0.5em;
    border-radius: 0.5em;
    
    font-size: 1.25em;
    z-index: 10;
    font-weight: 700;
    text-align: center;
}

.dont-send-now-btn{
    margin-top: 2em;
    width: 50%;
    cursor: pointer;
    pointer-events: visible;
    color: white;
    background-color: black;

    border: 2px solid black;
    padding: 0.35em 0.5em 0.35em 0.5em;
    border-radius: 0.5em;
    
    font-size: 1.25em;
    z-index: 10;
    font-weight: 700;
    text-align: center;
}

.reply-portrait{
    min-width: 100px;
    width: 100%;
    min-height: 300px;
    object-fit: contain;
    flex-grow: 1;
    border-radius: 0.5em;
}

.reply-portrait-parent{
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}