.cart-parent{
    width: 100%;
    display: flex;
    padding: 1.5em;
    align-items: center;
    justify-content: space-around;
    /* background-color: #e6dab920; */
    border-radius: 1em;
    box-sizing: border-box;
    margin-bottom: 1em;
}

.thumbnail-parent{
    width: 250px;
    height: 250px;
    padding: 1em;
    box-sizing: border-box;
}

.cart-thumbnail{
    width: 100%;
    height: 100%;
    border-radius: 1em;
    object-fit: contain;
    /* margin-top: 1em;
    margin-bottom: 1em; */
}

.contain{
    object-fit: contain !important;
}

.cart-details{
    
    text-align: left;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
}

.text-name{
    font-size: 1.5em;
}

.text-sku{
    font-size: 1.25em;
    margin-top: 0.35em;
}

.text-colour-size{
    font-size: 1.25em;
    margin-top: 0.35em;
    padding: 0.5em 1em 0.5em 1em;
    margin-top: 0.5em;
    background-color: #E6E6E6;
    border-radius: 0.5em;
}

.text-details{
    font-size: 0.75em;
    margin-top: 0.5em;
}

.text-price{
    font-size: 1.5em;
    margin-top: 1em;
}

.check-btn{
    max-width: 20%;
    width: 32px;
    padding-top: 1em;
    padding-bottom: 1em;
}

.selected-items-frame{
    width: -webkit-fill-available;
    background-color: #E6E6E6;
    border-radius: 1.5em;
}

.text-selected-items{
    
    display: flex;
    gap: 2em;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25em;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 0.5em 2em 0.5em 2em;
}

.text-selected-items-mobile{
    
    display: inline-flex;
    gap: 1em;
    flex-direction: column;
    justify-content: center;
    font-size: 1.25em;
    margin-top: 0.35em;
    padding: 0.5em 2em 0.5em 2em;
    margin-top: 2em;
    margin-bottom: 2em;
}