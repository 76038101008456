.title-parent{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    /* height: 350px; */
}

.backdrop{
    position: absolute;
    min-width: 65vw;
    width: 350px;
    max-width: 100vw;
    min-height: 450px;
    max-height: 850px;
    opacity: 0.55;
    filter: blur(5px);
    z-index: 0;
}

.title{
    /* position: fixed; */
    min-width: 25vw;
    width: 550px;
    max-width: 60vw;
    /*min-height: 120px;*/
    max-height: 650px;
    /* margin-top: 64px;*/
    margin-bottom: 64px;
    z-index: 1;
}

.description{
    text-align: center;
    width: 82vw;
    line-height: 1.5;
    margin-bottom: 1em;
    font-size: 1.125em;
    font-weight: 400;
    color:#955A1E;
}
.highlight-section{
    box-sizing:border-box;
    width:100vw;
    text-align:center;
    padding:2em 0;
    background:#F6F1F0;
}
.highlight-section img{
    width:100%;
    max-width: 840px;
    background:transparent;

}
.video-parent{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.video{
    /* position: absolute; */
    /* margin-top: 80px; */
    width: 70vw;
    max-width: 70vw;
    min-width: 30vw;
    height: auto;
    max-height: 90vh;
    z-index: 0;
    top: 0;
}

.video-wide{
    /* position: absolute; */
    /* margin-top: 80px; */
    /* width: 70vw;
    max-width: 70vw; */
    min-width: 30vw;
    height: auto;
    max-height: 90vh;
    z-index: 0;
    top: 0;
    width: 100%;
}

.play-button{
    position: absolute;
    z-index: 1;
    object-fit: contain;
    height: 64px;
    width: 64px;
    opacity: 0.55;
    /* top: 50%; */
}

.play-button:hover{
    opacity: 0.75;
}
.main-title{
    color:#955A1E;
    font-size:2em;
    margin:1em auto;
    text-align:center;
}
.pause-button{
    position: absolute;
    z-index: 1;
    object-fit: contain;
    height: 64px;
    width: 64px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    /* top: 50%; */
}

.pause-button:hover{
    opacity: 0.75;
}

.how-does-it-work{
    margin-top: 64px
}

.steps-layout{
    display:grid;
    grid-template-columns: 35vw 1px 35vw;
    grid-template-rows: 1fr 1px 1fr;
    /* grid-row-gap: min(5vw, 64px); */
    /* background-image: url("../Images/cross.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;  */
}

.steps-layout-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* grid-row-gap: min(5vw, 64px); */
    /* background-image: url("../Images/cross.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;  */
}

.step-text{
    margin-top: 32px;
    
    font-size: 2.25em;
    line-height: 1em;
    text-transform: uppercase;
}

.step-number{
    
    font-size: 1em;
    line-height: 1em;
    font-weight: normal;
}

.step-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.step-title{
    margin-top: 32px;
    color: #AD7855;
    
    font-weight: 900;
    font-size: 1.75em;
    text-transform: uppercase;
    padding-left: 5%;
    padding-right: 5%;
}
.div_loading{
    z-index:33;
    width:100%;
    height:100%;
    background:rgba(255,255,255,0.78);
    position:fixed;
    top:0;
    left:0;
}
.step-desc{
    margin-top: 12px;
    
    font-weight: 300;
    font-size: 1.125em;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 32px;
}
.name-position{
    margin-top: 4px;
    color: #AD7855;
    font-weight: normal;
    font-size: 1.25em;
    font-style:italic;
    padding-left: 5%;
    padding-right: 5%;
}

.dotted-border-v{
    width: 12px;
    background-image: url("../Images/dot.png");
    background-size: 12px;
    background-position: center;
    background-repeat: repeat-y;
    /* border-left: 5px dotted #888888;
    margin-left: 2px; */
    /* border-style: dotted; */
}
.slick-next:before, .slick-prev:before{
    content: "" !important;
}
.dotted-border-h{
    height: 12px;
    width: 100%;
    background-image: url("../Images/dot.png");
    background-size: 12px;
    background-position: center;
    background-repeat: repeat-x;
}

.center-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mini-highlight-text{
    
    font-size: 1.5em;
}

.highlight-text{
    
    font-size: 1.75em;
}

.showcase-parent{
    display: flex;
    /* grid-template-columns: repeat(3, 25vw);
    grid-column-gap: 16px;
    /* grid-auto-columns: 150px; */
    margin-bottom: 64px;
    /* height: 350px; */
}

.showcase-img{
    width: 22.5vw;
    height: 35vw;
    object-fit: cover;
    padding: 16px;
}

.input-parent{
    /* width: 100%; */
    
    color: #666666;
}

.input-title{
    display: flex;
    align-items: center;
    padding-top: 1.5em;
    padding-bottom: 1em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    /* width: 100%; */
}

.input-icon{
    width: 3em;
    height: 3em;
    object-fit: contain;
    padding-right: 1em;
}

.full-width{
    width: 100%;
}

.half-width{
    width: 50%;
}

.hori{
    display: flex;
    flex-direction: row;
}

.grid-2{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
}
.image{
    height:auto;
    width:100%;
    max-width:640px;
}
.margin-top{
    margin-top:3em;
}

.float-bottom{
    position:fixed;
    width:80vw;
    left:10vw;
    bottom:1%;
    z-index:2;
    
}

.float-bottom .black-button{
    width:100%;
    padding: 0 !important;
    margin: 0 auto !important;
    box-sizing:border-box; 
    line-height:3 !important;
    font-size:1.3em !important;
    justify-content: center;
    border-radius:80px;
    font-weight:bold;
}
.float-support{
    position:fixed;
    bottom:108px;
    right:12px;
    z-index:1;
    width:auto;
    height:48px;
    cursor:pointer;
}
.float-top{
    position:fixed; 
    right:0;
    top:0;
    z-index:2;
    display:none;
}
.whole-section{
    justify-content: center;
    display: grid;
    width: 100vw;
    position: relative;
    margin: 0;
    background:#f6f1f0;
    padding:2em 0;
    box-sizing: border-box;
}
.top-button-inline{
    display:inline-block;
    background:#000;
    color:#fff;
    font-size:1em;
    text-align:center;
    padding: 0 1em;
    margin-right:0.5em;
    line-height:1.5;
    cursor:pointer;
    border-radius:3px;
}
.div-example-img{
    display:inline-block;
    width:100%;
    margin:0 auto;
    text-align:center;
}
.separator_block{
    height:48px;
    width:100vw;
    box-sizing:border-box;
    background:#f7f1f0;
    text-align:center;
    margin:1em auto 1em -5%;
}
.div-example-img img{
    width:30%;
    max-width:250px;
    height:auto;
    border-radius:8px;
    margin:0 1%;
}
.subdescription{
    color:#333;
    line-height: 1.5;
    margin: 1em auto 2em auto;
    font-size: 0.7em; 
}
@media (min-width:481px){
    .float-bottom{
        width:360px;
        left:50%;
        margin-left:-180px;
    }
    .float-bottom .black-button{
        font-size: 1.3em !important;
    }
}
@media (max-width:480px){
    .highlight-section img{
        width:90%;
    }
}