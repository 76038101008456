input[type],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 16px 0px;
    display: inline-block;
    /* border: 1px solid #ccc; */
    /* border-radius: 4px; */
    background-color: transparent;
    border: none;
    outline: none;
    color: #333333;
    box-sizing: border-box;
    font-size: 1em;
    font-weight: 400;
}
input:focus {
    outline: none;
}

.option-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    width: 100%;
}

.option-btn-selected{
    cursor: pointer;
    width: 50%;
    padding: 1em;
    box-sizing: border-box;
    background: #333333;
    color: white;
    border-radius: 1em;
    
    font-size: 1.25em;
    font-weight: 700;
}

.option-btn-not-selected{
    cursor: pointer;
    width: 50%;
    padding: 1em;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #333333;
    color: #333333;
    border-radius: 1em;
    
}

.dashboard-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 850px;
    padding: 5%;
    box-sizing: border-box;
}

.dis-content{
    display: contents;
}

.response-parent{
    /* cursor: pointer; */
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #33333340;
    color: #333333;
    border-radius: 0.5em;
    margin-top: 1em;
    text-align: start;
    gap: 0.75em;
    display: flex;
    justify-content: space-between;
}

.response-info{
    display: flex;
    flex-direction: column;
    gap: 0.25em;
}

.response-parent:hover{
    background: #33333310;
}

.small-text{
    font-size: 0.75em;
}

.no-item{
    
    font-size: 1.25em;
}

.search-bar{
    margin-top: 1em !important;
    margin-bottom: 2em !important;
    width: 100%;
}

.alert{
    color: red;
    font-weight: 700;
}

.ok{
    font-weight: 700;
    color: green;
}

.divider{
    width: 100%;
    height: 1px;
    opacity: 0.5;
}
.link{
    padding: 1em;
    margin-bottom: 1.5em;
}