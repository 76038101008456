@import url('https://fonts.googleapis.com/css2?family=Brygada+1918:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;500;600&family=PT+Serif:wght@400;700&family=Raleway:wght@300;400;500;600;700&display=swap');
@import url('https://allfont.net/allfont.css?fonts=raleway-semibold');



html {
    scroll-behavior: smooth;
}
body{
    font-family: Raleway Semibold !important;
}
.survey-question, .input-title, .display-title{
    font-family: MadeMirageMedium !important;
}
input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    /* border: 1px solid #ccc; */
    /* border-radius: 4px; */
    background-color: transparent;
    border: none;
    outline: none;
    color: #333333;
    box-sizing: border-box;
    font-size: 1.5em;
    font-weight: 700;
}
input:focus {
    outline: none;
}

.parent{
    position: relative;
    width: 100%;
}

.display-title{
    font-size: 3em;
    font-weight: 600;
    color: #955A1E;
    margin: 0.5em auto 1em auto;
}

.logo{
    min-width: 10vw;
    width: 200px;
    max-width: 78vw;
    margin: 1em auto;
    height: auto;
}

.isolate{
    margin-top: 64px;
    margin-bottom: 64px;
    z-index:1;
    /* max-width: 80vw; */
}

.mini-isolate{
    margin: 16px;
}

.margin-top-l{
    margin-top: 64px;
}

.margin-top-m{
    margin-top: 32px;
}

.margin-top-s{
    margin-top: 16px;
}

.margin-top-xs{
    margin-top: 8px;
}

.deco{
    /* height: 80vh; */
    width: 50vw;
    /* max-width: 100vw; */
    position: absolute;
    left: 0;
    top: 0;
    object-fit: contain;
    pointer-events: none;
}

.flip-h{
    -moz-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left:2em;
    padding-right:2em;


}

.black-button{
    width: fit-content;
    cursor: pointer;
    pointer-events: visible;
    color: white;
    background-color: #738046;
    padding: 0.3em;
    border-radius: 3em;
    font-size: 1em;
    z-index: 10;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    margin:0 auto;
    line-height:1.5;
}

.black-button-text{
    padding: 0em 1.5em;
}

.black-button:hover{
    cursor: pointer;
}

.black-button-relative{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.try-next-icon{
    height: 32px;
    margin-left: 0.75em;
    position: absolute;
    right: 0.5em;
}

.inline-icon{
    width:3.5em;
    height:3.5em;
    object-fit: contain;
    padding: 0 0.75em;
}