.footer-parent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-vertical {
    flex-direction: column;
}

.footer-img {
    max-width: 256px;
    width: 22vw;
}
.inline-icon{
    width:36px;
    height:36px;
}
.footer-text {
    
    color: black;
    text-decoration: none;
    font-size: 1.25em;
    font-weight: 500;
    padding: 0.25em 0.75em;
}
