@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,900&display=swap');

.faq-question{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1em;
    font-weight: 700;
    /* width: 100%; */
    text-transform: uppercase;
    /* margin-top: 8px;
    margin-bottom: 8px; */
    /* padding-left: 2.5%;
    margin-right: 2.5%; */
    cursor: pointer;
    z-index: 10;
}

.faq-question-text{
    width: calc(100% - 84px);
    text-align: left;
    cursor: pointer;
    text-transform: capitalize;
    font-weight: 600;
}

.faq-answer{
    /* padding-left: 2.5%; */
    /* margin-right: 2.5%; */
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    text-transform: initial;
    width: 100%;
    /* margin-top: 4px;
    margin-bottom: 4px; */
    line-height: 1.5em;
    height: fit-content;
    transition: opacity 0.125s ease-in-out, max-height 0.25s ease-in-out;
    z-index: 0;
    pointer-events: none;
    color:#666;
}

.img-button{
    cursor: pointer;
    width: 1em;
    height: 1em;
    padding: 1em;
}

.divider{
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 0.5em 0;
}

.pad-h{
    padding: 0.5em 0.5em 1em 0.5em;
}