.landing-page-parent {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

.landing-page-top-bar {
    padding: 1.5em 0em;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-page-title-bar {
    background: #F7F2EE;
    font-family: "Butler";
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    padding: 1.5em 0;
    gap: 0.5em;
}

.landing-page-title-bar *{
    font-family: "Butler";
    font-size: 14px;
}

.landing-page-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.landing-page-main-title {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-size: 32px;
    font-family: 'MadeMirage';
    font-weight: 600;
}

.landing-page-main-subtitle {
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 1.65em;
}

.landing-page-try-now-button {
    background: Black;
    color: white;
    padding: 0.5em 1.25em;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    border: 2px solid black;
}

.landing-page-try-now-button:hover{
    background: white;
    color: black;
}

.landing-page-advertisment {
    background: #5DD5A7;
    height: 500px;
    width: 100%;
}

.landing-page-steps {
    background: #F7F2EE;
    height: auto;
    padding-top: 3em;
    padding-bottom: 3em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.step-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1em;
}

.step-image{
    aspect-ratio: 1;
    padding: 1em;
    max-width: 256px;
    box-sizing: border-box;
}

.step-image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.steps-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.steps-parent {
    gap: 2em;
    width: 100%;
    display: grid;
    max-width: min(896px,100vw);
    grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
    padding: 0 1.5em;
    box-sizing: border-box;
}


.step-title {
    font-size: 18px;
    font-weight: 700;
    font-family: 'Roboto';
    margin-bottom: 0.5em;
}

.step-description {
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 1.5em;
}

.landing-page-title {
    font-size: 32px;
    font-family: 'MadeMirage';
    font-weight: 600;
    /* margin-bottom: 1.5em; */
}

.landing-page-customization{
    background:white;
    height: auto;
    padding-top: 5em;
    padding-bottom: 5em;
    width: 100%;
}

.landing-page-customization-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:2em;
}

.customization-image-container {
    padding: 1em;
    height: 100%;
    width: 100%;
    margin: 1em;
    box-sizing: border-box;
    overflow: hidden;
}

.customization-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 4px solid black;
}

.landing-page-questionaire{
    background: #F7F2EE;
    height: auto;
    padding-top: 3em;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.m-b-l{
margin-bottom: 1.5em;
}

.slick-next:before{
    content: unset;
}
.slick-prev:before{
    content: unset;
}
.slick-next{
    right: 24px !important;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.slick-next:hover{
    box-shadow: 0 0 16px rgba(0,0,0,0.5);
}

.slick-prev{
    left: 24px !important;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 99;
}
.slick-prev:hover{
    box-shadow: 0 0 16px rgba(0,0,0,0.5);
}

.slick-prev:before, .slick-next:before{
    font-size: unset;
}