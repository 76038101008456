.new-item-parent{
    border: 1px solid #333333;
    color: #333333;
    border-radius: 0.5em;
    padding: 1em;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.item-title{
    
    font-weight: 700;
    font-size: 1.5em;
    margin-bottom: 1em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.top-title-bar{
    display: flex;
    justify-content: space-between;
}

.delete-icon{
    width: 2em;
    height: 2em;
}

.add-photo-btn{
    cursor: pointer;
    width: fit-content;
    text-align: center;
    padding: 0.5em;
    box-sizing: border-box;
    background: #00000010;
    border: 1px solid #333333;
    color: #333333;
    border-radius: 0.5em;
    font-size: 1em;
    font-weight: 700;
    
}

.cover-img{
    width: 250px;
    height: 250px;
    object-fit: contain;
    border-radius: 0.5em;
}
