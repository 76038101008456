@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,900&display=swap');

.all-carts{
    width: 100%;
}
#checkoutpage .input-title{
    text-align: left !important;
    margin-top:1.3em !important;
}
.clamp-width{
    max-width: 830px;
    width: 100%;
}

.error{
    color: red;
    font-size: 0.95em;
}

.margin-bot-s{
    margin-bottom: 16px;
}

.loading-bg{
    background: #000000D0;
    backdrop-filter: blur(3px);
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 200;
    gap: 1em;
    padding: 1em;
    box-sizing: border-box;
    top: 0;
}

.send-btn{
    margin-top: 2em;
    width: 100%;
    cursor: pointer;
    pointer-events: visible;
    color: white;
    background-color: black;
    padding: 0.35em 0.5em 0.35em 0.5em;
    border-radius: 0.5em;
    
    font-size: 1.25em;
    z-index: 10;
    font-weight: 700;
    text-align: center;
}

.alert-text{
    color: white !important;
    font-weight: 700;
    
}

.white-border{
    border: 2px solid white;
}

.send-now-btn{
    margin-top: 2em;
    width: 50%;
    cursor: pointer;
    pointer-events: visible;
    color: black;
    background-color: white;
    padding: 0.35em 0.5em 0.35em 0.5em;
    border-radius: 0.5em;
    
    font-size: 1.25em;
    z-index: 10;
    font-weight: 700;
    text-align: center;
}

.dont-send-now-btn{
    margin-top: 2em;
    width: 50%;
    cursor: pointer;
    pointer-events: visible;
    color: white;
    background-color: black;

    border: 2px solid black;
    padding: 0.35em 0.5em 0.35em 0.5em;
    border-radius: 0.5em;
    
    font-size: 1.25em;
    z-index: 10;
    font-weight: 700;
    text-align: center;
}
@media print {
    .isolate ,.black-button, .selected-items-frame, .check-btn{display:none;}
    .thumbnail-parent{height:100px !important; width:100px !important; padding-top: 0 !important;}
    .text-name, .text-price{font-size:1em !important;}
    .text-sku{font-size:0.825em !important;}
    .cart-parent{padding: 0 1.5em !important; margin-bottom:0 !important}
}