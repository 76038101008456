.isolate {
    margin-top: 32px;
    margin-bottom: 64px;
    /* max-width: 80vw; */
}

.mini-isolate {
    margin: 16px;
}

.survey-center-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mini-highlight-text {
    
    font-size: 1.5em;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.highlight-text {
    
    font-size: 1.75em;
}

.input-parent {
    width: 100%;
    /* border-bottom: 1px solid #333333; */
    color: #955A1E;
}

.input-parent input[type="text"], select{
    width: 100%;
    border: 1px solid #955A1E;
    border-radius: 30px;
    background-color: transparent;
    outline: none;
    color: #333333;
    box-sizing: border-box;
    font-size: 1.3em;
    font-weight: normal;
    padding: 0;
    line-height: 2;
    margin:0;
    padding-left:10px;
}

.input-title {
    display: flex;
    align-items: center;
    padding: 1em;
    font-size: 1.3em;
    font-weight: normal;
    padding: 0;
    line-height: 2;
    text-align: right;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding-right: 10px;
}

.question-padding{
    /*padding: 1em 1.5em 1em;*/
    
    box-sizing: border-box;
}
.div_quiz_selection{
    display: inline-block;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    color:#333;
    cursor: pointer;
    margin: 0.5em;
}
.div_quiz_text{
    color:#333;
    text-align:center;
    margin:0.5em 0;
}
.quiz_selection{
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.input-icon {
    width: 3em;
    height: 3em;
    object-fit: contain;
    padding-right: 1em;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.hori {
    display: inline-flex;
    flex-direction: row;
}

.clean-bot{
    margin-bottom: 0px !important;
}

.all-input {
    display: block;
    grid-gap: 16px;
    justify-content: center;
    grid-template-columns: 50% 50%;
    align-items: baseline;
    width:70%;
}
@media screen and (max-width: 650px) {
    .all-input {
        grid-template-columns: 90%;
    }
}

.no-border{
    border: 0px !important;
}

.survey-question{
    font-size: 1.3em;
    line-height:1.3;
    color:#955A1E;
    margin:2.5em auto 1em auto;
}
.check-btn{
    max-width:unset !important;
    width:20px !important;
    cursor: pointer;
}
.survey-hint{
    font-size: 1em;
    font-weight: normal;
    padding: 6px 20px;
    margin-top:-12px;
    margin-bottom:12px;
    color:#955A1E;
}
.text-start{
    text-align: start;
}

.center-everything{
    justify-content: flex-start;
    align-items: center;
}

.pad-l{
    padding-left: 1.5em;
    gap: 16px;
}

.answer{
    font-size: 1.25em;
    cursor:pointer;
}

.top-btm{
    padding-right: 1em;
    font-size: 1.25em;
    font-weight: normal;
    width: -webkit-fill-available;
}

.top-btm-list{
    padding: 12px 20px;
    margin-top: 1em;
    gap: 1em;
    align-items: center;
}

.btn-wrap{
    border: 1px solid #945a1c;
    padding: 0;
    border-radius: 30px;
    cursor: pointer;
    width: 100%;
}

.skin-tone{
    max-width: 100%;
    height: auto;
    max-height: 100px;
    object-fit: contain;
}

.dis-content{
    display: contents;
}

.margin-bot{
    margin-bottom: 64px;
}

.dialog{
    position: fixed;
    left: 10%;
    width: 80%;
    height: 250px;
    background: white;
    z-index: 100;
}

.margin-bot-s{
    margin-bottom: 16px;
}

.error{
    color: red;
    font-size: 0.95em;
}

.loading-bg{
    background: #00000080;
    backdrop-filter: blur(3px);
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:2;
}

.survey-portrait-images-content{
    padding: 0.5em;
    border: 2px solid #b1b0a8;
    box-sizing: border-box;
    align-items: center;
    min-height: 150px;
    margin-bottom: 1em;
    border-radius: 1em;
    display: flex;
}

.survey-portrait-images-box{
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
}

.survey-portrait{
    min-width: 100px;
    width: 100%;
    height: 150px;
    object-fit: contain;
    flex-grow: 1;
    border-radius: 0.5em;
}

.survey-portrait-parent{
    height: 100%;
    position: relative;
}

.remove-portrait-icon{
    top: 0;
    right: 0;
    position: absolute;
    background-color: aliceblue;
    margin: 0.25em;
    padding: 0.25em;
    border-radius: 2em;
}

.m-a-m{
    margin: 8px;
}

.center-text{
    text-align: center;
}
.flex_div{
    width:25vw;
    height:25vw;
    display:inline-flex;
    text-align:center;
}
.color-title{
    text-align:center;
    width:100%;
}
.round-color-button{
    border-radius:50%;
    width:80%;
    height:80%;
}

.separator{
    height:1px;
    width:100vw;
    box-sizing:border-box;
    background-color:#ccc;
    margin:2em auto;
    margin-left:calc(100vw*-0.05);
}
.ul-input {
    list-style: none;
    padding: 0;
    line-height: 2;
    display:flex;
    margin:1em auto;
}
.multiple-choice .ul-input{
    max-width:520px;
    margin:0.5em auto;
}
.ul-input li{
    display:inline;
}
.ul-input .li-input-title{
    width:30%;
}
.ul-input .li-input-input{
    flex:1;
}

.column_4_list{
    display: inline-block;
    grid-gap: 12px;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: baseline;
    text-align: center;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #d6d6d6;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #d6d6d6;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #d6d6d6;
}
.color-list{
    display: inline-block;
    grid-gap: 12px;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: baseline;
    max-width:890px;
}
.color-picker-options{
    display: inline-block;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    cursor: pointer;
    border: 1px solid black;
    margin: 0.5em;
}
.dropdown-btn{
    color:#333;
    /*padding-left:10px;*/
    box-sizing:border-box;
    /*text-align:left;*/
}
@media (min-width:481px){
    .flex_div{
        width:64px;
        height:64px;
    }
}
@media (max-width:480px){
    .input-title{
        padding:0.5em;
        text-align:left;
    }
    .input-parent, .survey-hint, .survey-question{
        text-align: left;
    }
    .survey-hint{
        padding: 6px 0;
    }
    .input-parent input[type="text"], select{
        font-size:1.3em;
    }
    .survey-question{
        font-size:1.3em;
    }
    .all-input{
        width:100%;
    }
    .ul-input{
        display: inline;
    }
    .dropdown-btn{
        padding: 0 0.5em;
        box-sizing: border-box;
    }
    .multiple-choice .ul-input{
        text-align:left;
    }
    .column_4_list{
        grid-template-columns: 1fr 1fr !important;
    }

    .color-list{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}



