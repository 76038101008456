.center{
    display: flex;
    justify-content: center;
}

.thankyou{
    width: 90%;
    border-radius: 2em;
}

.faq-parent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
}

.faq-title{
    
    font-size: 2.5em;
    margin-bottom: 1em;
    font-weight: 600;
}

.centric{
    max-width: 750px;
}

.query-text{
    font-size: 1.5em !important;
    font-weight: 500;
}

.contact-text{
    font-size: 2.5em !important;
}

.reference-parent{
    
    background: #333333;
    border-radius: 2em;
    color: white;
    width: 90%;
    font-size: 2em;
    padding: 1em 2em 1em 2em;
    box-sizing: border-box;
    display:none;
}

.bold{
    font-weight: 700;
}
.display-title a{
    text-decoration:none;
    color:#333;
}
.big-display-title{
    
    font-size: 5em;
    font-weight: 600;
    line-height:1.5;
    letter-spacing:2px;
    margin-top:1em;
}
.mid-display-title{
    
    font-size: 2em;
    font-weight: 600;
    letter-spacing:2px;
    line-height:1.4;
    margin-bottom:3em;
}