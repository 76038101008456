input[type],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 16px 0px;
    display: inline-block;
    /* border: 1px solid #ccc; */
    /* border-radius: 4px; */
    background-color: transparent;
    border: none;
    outline: none;
    color: #333333;
    box-sizing: border-box;
    font-size: 1em;
    font-weight: 400;
}
input:focus {
    outline: none;
}

.admin-login-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.admin-login-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    width: 300px;
    gap: 1em;
}

.text-field{
    width: 100%;
}

.login-btn{
    cursor: pointer;
    width: 50%;
    padding: 1em;
    box-sizing: border-box;
    background: #333333;
    color: white;
    border-radius: 1em;
    
    font-size: 1.25em;
    font-weight: 700;
}